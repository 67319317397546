import request from "@/api/config.js"; 

export function GetEventsList() {
  return request({
    url: `events/get/events/list`,
    method: "get",
  });
}
export function GetEventWithUsers(data) {
  return request({
    url: `events/post/event/info`,
    method: "post",
    data
  });
}
export function GetAllUsers(data) {
  return request({
    url: `events/post/users/all`,
    method: "post",
    data
  });
}


export function CreateEvent(data) {
  return request({
    url: `events/post/events/create`,
    method: "post",
    data
  });
}
export function UpdateEvent(data) {
  return request({
    url: `events/post/events/update`,
    method: "post",
    data
  });
}
export function RemoveEvent(data) {
  return request({
    url: `events/post/events/remove`,
    method: "post",
    data
  });
}

export function AddUser(data) {
  return request({
    url: `events/post/events/add-user`,
    method: "post",
    data
  });
}
export function RemoveUser(data) {
  return request({
    url: `events/post/events/remove-user`,
    method: "post",
    data
  });
}