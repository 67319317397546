<template>
  <div class="Page-Wrapper">
    <SortingWithFiltersPreset
      class="SFPRestyle"
      title="Администрирование событий"
      :onlySearch="true"
      searchPlaceholder="Поиск события"
      :searchAttribures="['name', 'desc']"
      :originalArray="Mentors.original"
      :setNewArrayCallback="handleArrayUpdate"
      :sortMethodCallback="handleSort"
      :filtersButtons="FiltersButtons"
      :sortOptions="[{ text: 'Сортировка по умолчанию', val: 'default' }]"
      :filterButtonModeServer="true"
      :callbackForFilterButton="handleFilter"
      :postFiltersDataChanges="handleFilterChanges"
      :setFiltersData="Filters"
      :key="'SortingFiltersSearch-' + mentors_hash"
    >
      <template v-slot:content>
        <div class="SearchButtonsWrapper">
          <div class="SearchButtons">
            <OUIButtonPrimary
              @click="openEditModal(null)"
              ButtonText="Добавить событие"
              :size="'medium'"
            />
          </div>
        </div>
      </template>
    </SortingWithFiltersPreset>

    <div class="MentorsList" :key="`mentors_filtered_hash`">
      <div
        v-for="(mentor, mindx) in Mentors.filtered"
        :key="`mentor-${mindx}-${mentor.id}`"
        class="Mentor noselect"
      >
        <span class="MentorFIO">{{ mentor.name }}</span>
        <span class="MentorFIO"
          >{{ new Date(mentor.startDate).toLocaleDateString("ru-RU") }} -
          {{ new Date(mentor.finishDate).toLocaleDateString("ru-RU") }}</span
        >

        <div class="Buttons">
          <router-link :to="`/admin/event/users?id=${mentor.id}`">
            <OUIButtonPrimary ButtonText="Студенты" />
          </router-link>
          <OUIButtonPrimaryOutline
            @click="openEditModal(mentor)"
            ButtonText="Редактировать"
          />
          <OUIButtonTertary
            @click="handleRemoveModal(mentor.id)"
            ButtonText="Удалить"
          />
        </div>
      </div>
    </div>

    <NewStyledModal
      class="Styled-Modal"
      :showModal="EditModal.show"
      :hideModal="closeEditModal"
      :key="EditModal.show"
    >
      <template v-slot:ModalContent>
        <div class="Offer-Modal" v-if="EditModal.show">
          <span class="Offer-Modal-Title">
            {{
              EditModal.id != null
                ? "Редактирование события"
                : "Создание события"
            }}
          </span>

          <div class="Offer-Modal-Content">
            <div class="Input-Floating-Label">
              <input
                :class="{
                  'Input-styled': true,
                  'Input-highligt-error':
                    EditModal.name === '' || EditModal.name.length < 3,
                }"
                type="text"
                placeholder="Название"
                autocomplete="off"
                maxlength="64"
                v-model="EditModal.name"
              />
              <span class="Input-Placeholder noselect">Название</span>
              <span class="Input-Text-Length noselect">{{
                64 - EditModal.name.length
              }}</span>
            </div>

            <div class="Input-Floating-Label">
              <div
                class="QEditor QEditorFixHeight"
                style="position: relative; width: 640px; height: 500px"
              >
                <QuillEditor
                  ref="QuillEditor"
                  toolbar="full"
                  theme="snow"
                  contentType="html"
                  :content="EditModal.desc"
                  @textChange="handleEditorEditChanges($event)"
                  @ready="handleEditorEditReady()"
                />
              </div>
            </div>

            <div class="Input-Floating-Label">
              <input
                :class="{
                  'Input-styled': true,
                  'Input-highligt-error': EditModal.startDate === null,
                }"
                v-model="EditModal.startDate"
                type="date"
                placeholder="Дата начала"
              />
              <span class="Input-Placeholder noselect">Дата начала</span>
            </div>

            <div class="Input-Floating-Label">
              <input
                :class="{
                  'Input-styled': true,
                  'Input-highligt-error': EditModal.finishDate === null,
                }"
                v-model="EditModal.finishDate"
                type="date"
                placeholder="Дата окончания (включительно)"
              />
              <span class="Input-Placeholder noselect"
                >Дата окончания (включительно)</span
              >
            </div>
          </div>

          <div class="Offer-Modal-Buttons noselect">
            <span class="Offer-Button-Prev" @click="closeEditModal"
              >Отмена</span
            >
            <span class="Offer-Button-Next" @click="createOrUpdateMentor">{{
              EditModal.id != null ? "Cохранить" : "Создать"
            }}</span>
          </div>
        </div>
      </template>
    </NewStyledModal>
  </div>
</template>

<script>
import SortingWithFiltersPreset from "@/components/CustomElements/SortingWithFiltersPreset.vue";
import OUIButtonPrimary from "@/components/OctaUI/Buttons/primary.vue";
import OUIButtonPrimaryOutline from "@/components/OctaUI/Buttons/primary_outline.vue";
import OUIButtonTertary from "@/components/OctaUI/Buttons/tertary.vue";
import NewStyledModal from "@/components/CustomElements/NewStyledModal.vue";

import { QuillEditor } from "@vueup/vue-quill";

import {
  GetEventsList,
  CreateEvent,
  UpdateEvent,
  RemoveEvent,
} from "@/api/events.js";

import { ElMessage, ElMessageBox } from "element-plus";

export default {
  data() {
    return {
      Filters: {
        button: null,
        search: "",
        sorting: "default",
      },
      FiltersButtons: [],

      Mentors: {
        original: [],
        filtered: [],
      },

      EditModal: {
        show: false,
        id: null,
        name: "",
        desc: "",
        startDate: null,
        finishDate: null,
      },

      mentorForDelete: null,

      SectionsList: [],
    };
  },
  components: {
    SortingWithFiltersPreset,
    OUIButtonPrimary,
    OUIButtonPrimaryOutline,
    NewStyledModal,
    OUIButtonTertary,
    QuillEditor,
  },
  computed: {
    mentors_hash() {
      let a = this.Mentors.original.map(
        (x, xindx) =>
          `${xindx}-${x.id}-${x.name}-${x.desc}-${x.startDate}-${x.finishDate}`
      );
      return `${this.Mentors.original?.length}-${a.join("-")}`;
    },
    mentors_filtered_hash() {
      let a = this.Mentors.filtered.map(
        (x, xindx) =>
          `${xindx}-${x.id}-${x.name}-${x.desc}-${x.startDate}-${x.finishDate}`
      );
      return `${this.Mentors.filtered?.length}-${a.join("-")}`;
    },
  },
  async created() {
    await this.initialization();
  },
  methods: {
    formatDate(date) {
      date = new Date(date);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },

    handleEditorEditChanges(delta) {
      this.EditModal.desc = this.$refs.QuillEditor.getHTML();
    },
    handleEditorEditReady() {
      this.$refs.QuillEditor.setHTML(this.EditModal.desc);
    },

    async initialization() {
      let listResp = await GetEventsList();
      this.Mentors.original = listResp.data;
    },

    getSectionsForMentor(MentorDirections) {
      return MentorDirections == null
        ? []
        : MentorDirections.map((x) => x.name);
    },

    openEditModal(Mentor) {
      if (Mentor != null) {
        this.EditModal.id = Mentor.id;
        this.EditModal.name = Mentor.name;
        this.EditModal.desc = Mentor.desc;
        this.EditModal.startDate = this.formatDate(Mentor.startDate);
        this.EditModal.finishDate = this.formatDate(Mentor.finishDate);
      }
      this.EditModal.show = true;
    },
    closeEditModal() {
      this.EditModal = {
        show: false,
        id: null,
        name: "",
        desc: "",
        startDate: null,
        finishDate: null,
      };
    },
    async createOrUpdateMentor() {
      if (this.EditModal.blockSaveButton) {
        return 0;
      } else {
        this.EditModal.blockSaveButton = true;
      }

      if (this.EditModal.id == null) {
        try {
          let resp = await CreateEvent({
            name: this.EditModal.name,
            desc: this.EditModal.desc,
            startDate: new Date(this.EditModal.startDate),
            finishDate: new Date(this.EditModal.finishDate),
          });

          let isError = resp?.data?.status === "fail";

          if (isError) {
            ElMessage.error(resp?.data?.msg);
            this.EditModal.blockSaveButton = false;
            return 0;
          }

          ElMessage({
            message: "Событие успешно создано!",
            type: "success",
          });
          this.closeEditModal();
        } catch (e) {
          ElMessage.error("Произошла ошибка на стороне сервера");
        }
      } else {
        try {
          await UpdateEvent({
            id: this.EditModal.id,
            name: this.EditModal.name,
            desc: this.EditModal.desc,
            startDate: new Date(this.EditModal.startDate),
            finishDate: new Date(this.EditModal.finishDate),
          });
          this.closeEditModal();
        } catch (e) {
          alert("Произошла ошибка");
        }
      }

      await this.initialization();
      this.EditModal.blockSaveButton = false;
    },

    handleArrayUpdate(UpdatedArray) {
      this.Mentors.filtered = UpdatedArray;
    },
    handleSort(SortName, Array) {
      return Array;
    },
    handleFilter(val) {
      this.Filters.button = val;
    },
    handleFilterChanges(obj) {
      this.Filters.button = obj.button;
      this.Filters.search = obj.search;
      this.Filters.sorting = obj.sorting;
    },

    async handleRemoveModal(mentorID) {
      ElMessageBox.confirm("Вы действительно хотите удалить событие?").then(
        async () => {
          try {
            await RemoveEvent({
              id: mentorID,
            });

            ElMessage({
              message: "Событие удалено!",
              type: "success",
            });

            await this.initialization();
          } catch (e) {
            ElMessage.error("Произошла ошибка на стороне сервера");
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.Page-Wrapper {
  position: relative;
  display: flex;

  margin: 0% auto;
  padding-top: 100px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 44px;

  width: 100%;
  max-width: 1200px;
  min-height: 100svh;

  padding-right: 15px;

  overflow: auto;
}

.SearchButtons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: fit-content;
  height: fit-content;
}
.SearchButtonsWrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  width: fit-content;
  height: fit-content;
}

.MentorsList {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}
.Mentor {
  position: relative;
  display: flex;

  padding: 20px;

  min-width: 0;
  width: calc((100% - (24px * 3)) / 4);
  max-width: 100%;

  height: auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  flex-basis: auto;

  transition: 0.25s;

  background: url("./../../../assets/img/Admin/LogoBG.png");
  background-position: 98% 0%;
  background-size: contain;
  background-repeat: no-repeat;

  border-radius: var(--o-s-global-border-radius);
  background-color: #1e1b4a;
}
.Mentor:hover {
  background-color: #27235c;
}
.MentorFIO {
  position: relative;
  display: block;

  min-width: 0;
  width: fit-content;
  max-width: 100%;

  height: fit-content;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;

  word-break: keep-all;
  word-wrap: break-word;

  color: #ffffffe5;
}
.MentorDirections {
  position: relative;
  display: flex;

  min-width: 0;
  width: fit-content;
  max-width: 100%;

  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 14px;
  flex-wrap: wrap;
}
.MentorSection {
  position: relative;
  display: block;

  padding: 4px 8px;

  min-width: 0;
  width: fit-content;
  max-width: 100%;

  height: fit-content;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  line-height: 15.85px;
  letter-spacing: 0%;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  background-color: #656bff33;
}

.Buttons {
  position: relative;
  display: flex;

  margin-top: auto;
  padding-top: 12px;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4px;
}

.MultiDirectionsTip {
  position: relative;
  display: block;
  margin-top: 12px;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: none;
  color: #ed254e;
}
</style>

<style scoped>
select.Input-styled::-ms-expand {
  display: none;
}
select.Input-styled option {
  background-color: white;
  color: black;
}
.Styled-Modal:deep(.SendRequestModal) {
  min-width: 680px;
  width: fit-content;
  background: #1e1b4a;
}
.Offer-Modal {
  position: relative;
  display: flex;

  padding-top: 12px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: auto;
  height: fit-content;
}

.Offer-Modal-Title {
  position: relative;
  display: block;

  min-width: 320px;
  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.Offer-Modal-Content {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  min-width: 320px;
  width: auto;
  height: fit-content;
}

.Input-Floating-Label {
  position: relative;
  display: block;

  min-width: 320px;
  width: auto;
  height: fit-content;
}

.Input-styled {
  position: relative;
  display: block;

  padding: 12px 8px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  background-color: transparent;

  outline: 0;
  outline-offset: 0;

  border-radius: 4px;
  border: 1px solid #d3caff40;
}
textarea.Input-styled {
  min-height: 120px;
}

.Input-styled::placeholder {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #d3caff73;
}
.Input-styled:focus {
  border-color: #656bff;
}
.Input-styled.Input-highligt-error {
  border-color: #ed254e;
}
.Input-styled.Input-highligt-error:focus + .Input-Placeholder,
.Input-styled.Input-highligt-error + .Input-Placeholder {
  color: #ed254e;
}

.Input-Placeholder {
  position: absolute;
  display: block;

  top: 0;
  left: 18px;

  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, -50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Placeholder {
  color: #656bff;
}

.Input-Text-Length {
  position: absolute;
  display: block;

  bottom: 0;
  right: 18px;

  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, 50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Text-Length {
  color: #656bff;
}

.Offer-Modal-Buttons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Button-Next {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: 100%;
  height: fit-content;

  flex-shrink: 1;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff;
}
.Offer-Button-Prev {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  flex-shrink: 0;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff40;
}
.Offer-Button-Prev:hover {
  background-color: rgba(101, 106, 255, 0.144);
}

.Offer-Text-Before-Confirm {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #d3caffb2;
}
.Offer-Text-Before-Confirm .Text-Before-Confirm-Important {
  color: #ffffffe5;
}

.Offer-Modal-Buttons-Child-Wrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Text-Balance-After {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;

  color: #d3caff4d;
}
.Offer-Text-Error {
  position: relative;
  display: block;

  margin-top: 12px;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: none;
  color: #ed254e;
}
</style>
